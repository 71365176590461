import * as React from 'react';
import InsuranceSummaryPage, { InsuranceSummaryPageData } from '../../pages/InsuranceSummaryPage';
import { getOfferPageData, getPerson } from '../../services/api';
import { writeOfferBackJumpMarker } from '../routeHelper';
import { StateDefinition } from '../StateMachine';
import { NavigationAction, StateName } from '../StateMachineTypes';

export const insuranceSummaryPage: StateDefinition<InsuranceSummaryPageData> = {
    name: StateName.INSURANCE_SUMMARY_PAGE,
    validInboundStates: [
        StateName.BANKING_DETAILS_PAGE,
        StateName.CONFIDENTIALITY_RELEASE_PAGE,
        StateName.REQUEST_OFFER_DIVERGING_INSURED_PERSON_PAGE,
        StateName.REQUEST_OFFER_CHECKOUT_PAGE],
    transitions: [
        {
            test: (action, data) => action === NavigationAction.NEXT,
            newState: StateName.CONFIDENTIALITY_RELEASE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK,
            newState: StateName.BANKING_DETAILS_PAGE
        },
        // BreadCrumbs
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_TARIFF_DATA,
            newState: StateName.BIRTHDATE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_FEE,
            newState: StateName.FEE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_FEE_PAGE,
            newState: StateName.FEE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_BURIAL_PACKAGE,
            newState: StateName.BURIAL_PACKAGE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_FLEX_OPTION,
            newState: StateName.FLEX_OPTION_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_DYNAMIC_OPTION,
            newState: StateName.FEE_DYNAMIC_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_BANKING_DETAILS_PAGE,
            newState: StateName.BANKING_DETAILS_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_PERSONAL_INFORMATION,
            newState: StateName.INSURED_PERSON_PAGE

        },
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_REQUEST_OFFER,
            newState: StateName.REQUEST_OFFER_CHECKOUT_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_INSURANCE_START_PAGE,
            newState: StateName.INSURANCE_START_PAGE
        }
    ],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'insuranceSummary');
        const insuredPerson = response.insuredPersonId ? await getPerson(inputData.businessId, response.insuredPersonId) : undefined;
        const divergingInsuredPerson = response.divergingInsuredPerson;
        const policyHolder = (divergingInsuredPerson && response.policyHolderId) ? await getPerson(inputData.businessId, response.policyHolderId) : undefined;

        return {
            userInput: {
                insuranceBegin: response.insuranceBegin,
                insuranceFee: response.insuranceFee,
                insuranceSum: response.insuranceSum,
                burialPackage: response.burialPackage,
                feeDynamic: response.feeDynamic,
                flexOption: response.flexOption,
                paymentMethod: response.paymentMethod,
                variants: response.varianten,
                iban: response.iban,
                bic: response.bic,
                entitledPersons: response.entitledPersons,
                messages: response.messages,
                paymentPeriod: response.paymentPeriod,
                divergingInsuredPerson,
                insuredPerson,
                policyHolder
            },
            valueRanges: response.valueRanges
        };
    },
    onExit: async transitionDetails => {
        writeOfferBackJumpMarker(transitionDetails);
        return {};
    },
    render: (inputData, handleAction, updateApp) => {
        return <InsuranceSummaryPage
            storeState={{
                insuranceBegin: inputData.userInput.insuranceBegin,
                insuranceFee: inputData.userInput.insuranceFee,
                insuranceSum: inputData.userInput.insuranceSum,
                burialPackage: inputData.userInput.burialPackage,
                feeDynamic: inputData.userInput.feeDynamic,
                flexOption: inputData.userInput.flexOption,
                paymentMethod: inputData.userInput.paymentMethod,
                variants: inputData.userInput.variants,
                iban: inputData.userInput.iban,
                bic: inputData.userInput.bic,
                entitledPersons: inputData.userInput.entitledPersons,
                messages: inputData.userInput.messages,
                paymentPeriod: inputData.userInput.paymentPeriod,
                divergingInsuredPerson: inputData.userInput.divergingInsuredPerson,
                insuredPerson: inputData.userInput.insuredPerson,
                policyHolder: inputData.userInput.policyHolder,
                update: values => updateApp(values)
            }}
            valueRanges={inputData.valueRanges}
            handleAction={handleAction}
        />;
    }
};
